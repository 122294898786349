import React, { useEffect } from 'react'

import '../styles/global.css'
import { useDispatch } from 'react-redux'
import Header from './Header'
import { videoEnded } from '../redux/videoAction'
import { filterButtons, removeMenu } from '../util'

export default function Layout({children}) {
  const dispatch = useDispatch()

  useEffect(() => {
    filterButtons()
    removeMenu()
    dispatch(videoEnded(false))
  }, [])
  
  return (
    <>
      <Header />
      {children}
    </>
  )
}
