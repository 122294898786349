import { Link } from 'gatsby'
import React, { useRef } from 'react'
import { menuHandle } from '../util'
import logo from './../assets/img/logo-brageteatret-inverted.svg'

const Header = () => {
  const menuEl = useRef()

  return (
    <header className="header fixed top-0 left-0 right-0 z-80 bg-black w-full text-white">
      <div className="max-w-md mx-auto px-4 flex items-center h-15">
        <div className="logo font-brand text-base leading-4">
          <Link to="/" className="text-white no-underline">
            SKUDDSIKKER
            <br /> VEST
          </Link>
        </div>
        <nav className="header__menu ml-auto">
          <ul className="flex">
            <li className="text-xl">
              <Link
                to="/dikt"
                className="inline-block border-b-4 border-white no-underline text-white"
                activeClassName="bg-white font-black px-2 pt-1"
              >
                dikt
              </Link>
            </li>
            <li className="text-xl ml-12">
              <button
                type="button"
                className="inline-block border-b-4 border-white focus:outline-none"
                onClick={() => menuHandle(menuEl, 'open')}
              >
                meny
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <nav
        className="header__menu-popup bg-black fixed w-full h-full z-20 left-0 top-0 overflow-auto opacity-0 transition duration-500 transform -translate-y-10 hidden"
        ref={menuEl}
      >
        <div className="max-w-md mx-auto p-4 min-h-full flex flex-col">
          <div className="flex items-center">
            <div className="logo font-brand text-base leading-4">
              SKUDDSIKKER
              <br /> VEST
            </div>
            <button
              type="button"
              className="bg-brand text-black px-2 text-xl ml-auto line-through cursor-pointer focus:outline-none"
              onClick={() => menuHandle(menuEl, 'close')}
            >
              lukk
            </button>
          </div>
          <ul className="flex flex-col pt-2 mb-6 font-demi">
            <li className="mt-7">
              <Link to="/dikt" className="text-white inline-block no-underline">
                <span className="text-xs block">
                  Se alle
                </span>
                <span className="uppercase text-32">DIKT</span>
                <i className="block bg-brand height-2 mt-1" />
              </Link>
            </li>
            <li className="mt-7">
              <Link to="/" className="text-white inline-block no-underline">
                <span className="text-xs block">
                  En kort intro
                </span>
                <span className="uppercase text-32">
                  YAHYA HASSAN
                </span>
                <i className="block bg-brand height-2 mt-1" />
              </Link>
            </li>
            <li className="mt-7">
              <Link
                to="/fordypning"
                className="text-white inline-block no-underline"
              >
                <span className="text-xs block">
                  For deg som vil vite mer
                </span>
                <span className="uppercase text-32">
                  FORDYPNINGSMATERIELL
                </span>
                <i className="block bg-brand height-2 mt-1" />
              </Link>
            </li>
            <li className="mt-7">
              <Link
                to="/forestillingen"
                className="text-white inline-block no-underline"
              >
                <span className="text-xs block">
                  Om forestillingen
                </span>
                <span className="uppercase text-32">
                  SKUDDSIKKER VEST
                </span>
                <i className="block bg-brand height-2 mt-1" />
              </Link>
            </li>
            <li className="mt-7">
              <Link
                to="/brageteateret"
                className="text-white inline-block no-underline"
              >
                <span className="text-xs block">
                  Hvem står bak
                </span>
                <span className="uppercase text-32">
                  BRAGETEATRET
                </span>
                <i className="block bg-brand height-2 mt-1" />
              </Link>
            </li>
          </ul>

          <div className="mt-auto flex flex-col pt-13">
            <span className="text-sm mb-1 font-maison-meue-book">
              Produsert og presentert av
            </span>
            <div className="w-52 my-1">
              <img src={logo} alt="" />
            </div>
            <span className="text-xs mt-1 font-maison-meue-book">
              Med støtte fra Viken Fylkeskommune
            </span>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Header



