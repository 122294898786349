/* eslint-disable no-underscore-dangle */
import React from 'react'
import { graphql } from 'gatsby'
import SanityBlockContent from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node
      const id = getYouTubeId(url)
      return <YouTube videoId={id} />
    },
  },
}

export default function SinglePage({ data: { page } }) {
  return (
    <>
      <SEO title={page.title} />
      <Layout>
        <main className="text-white pt-32">
          <div className="max-w-md mx-auto p-4 pb-16">
            <h1 className="text-4xl uppercase mb-10 font-demi font-bold">
              {page.title}
            </h1>
            <SanityBlockContent
              className="flex flex-col space-y-7 page-content"
              blocks={page._rawText}
              serializers={serializers}
            />
          </div>
        </main>
        </Layout>
    </>
  )
}

export const query = graphql`
  query ($id: String) {
    page: sanityPage(id: { eq: $id }) {
      _rawText(resolveReferences: { maxDepth: 10 })
      title
    }
  }
`
